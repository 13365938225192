import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Container } from "reactstrap";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Conjuntos from "./views/Conjuntos";
import { useAuth0 } from "@auth0/auth0-react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./App.scss";
import { AutorizarUsuario, BackendApi } from "./services/HttpBaseService";
import Tablados from "./views/Tablados";
import Concursos from "./views/Concursos";
import Programacion from "./views/Programacion";
import EtapasConcurso from "./views/EtapasConcurso";
import Pencas from "./views/Pencas";
import BackdropWrapper from "./components/BackdropWrapper";
import Pronosticar from "./views/Pronosticar";
import Liguilla from "./views/Liguilla";
import Resultados from "./views/Resultados";
import ResultadosPenca from "./views/ResultadosPenca";
import PencaRedirect from "./views/pencaRedirect";
import Notificaciones from "./views/Notificaciones";
import CostoPublicidad from "./views/CalculadoraPrecios";
import LandingWebsite from "./views/LandingWebsite";
import BannersTablados from "./views/BannersTablados";
import ApkDownload from "./views/ApkDownload";
import Anunciantes from "./views/Anunciantes";
import ConcursoCambiarEstado from "./views/ConcursoCambiarEstado";
import AppRedirect from "./views/appRedirect";
import PoliticaPrivacidad from "./views/PoliticaPrivacidad";
import EliminarCuenta from "./views/EliminarCuenta";
import Dashboard from "./views/Dashboard";
import ResultadosLiguilla from "./views/ResultadosLiguilla";

const PageRoutes = () => {
  const {
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    error,
    logout
  } = useAuth0();
  const [reintentar, setReintentar] = useState(1);
  const [autorizado, setAutorizado] = useState(false);

  useEffect(() => {
    if (window.location.href.includes("/backoffice")) {
      if (reintentar <= 5) {
        setAutorizado(null);
        const getAuthToken = async () => {
          return await getAccessTokenSilently();
        };
        getAuthToken()
          .then(data => {
            BackendApi.defaults.headers.common["Authorization"] =
              "Bearer " + data;
            AutorizarUsuario()
              .then(data => {
                if (data.data == false) logout();
                else setAutorizado(data.data);
              })
              .catch(err => {
                if (err?.response?.status == 401) {
                  setAutorizado(false);
                  logout();
                } else {
                  console.log(err);
                  setTimeout(() => setReintentar(reintentar + 1), 1000);
                }
              });
          })
          .catch(err => {
            loginWithRedirect();
          });
      }
    }
  }, [getAccessTokenSilently, reintentar]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <>
      {!isLoading && autorizado ? (
        <div>
          <BackdropWrapper />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
              <div id="app" className="d-flex">
                <NavBar className="flex-column h-100" />

                <Container className="backoffice flex-column h-100 m-2">
                  <Routes>
                    <Route exact path="/backoffice" element={<Home />} />
                    <Route
                      exact
                      path="/backoffice/profile"
                      element={<Profile />}
                    />
                    <Route
                      exact
                      path="/backoffice/conjuntos"
                      element={<Conjuntos />}
                    />
                    <Route
                      exact
                      path="/backoffice/concursos"
                      element={<Concursos />}
                    />
                    <Route
                      exact
                      path="/backoffice/tablados"
                      element={<Tablados />}
                    />
                    <Route
                      exact
                      path="/backoffice/pencas"
                      element={<Pencas />}
                    />
                    <Route
                      exact
                      path="/backoffice/programacion"
                      element={<Programacion />}
                    />
                    <Route
                      exact
                      path="/backoffice/programacion/:_tabladoId"
                      element={<Programacion />}
                    />
                    <Route
                      exact
                      path="/backoffice/etapas-concurso/:_concursoId"
                      element={<EtapasConcurso />}
                    />
                    <Route
                      exact
                      path="/backoffice/concurso/:_concursoId/cambiarEstado/:_estadoActual"
                      element={<ConcursoCambiarEstado />}
                    />
                    <Route
                      exact
                      path="/backoffice/liguilla/:_concursoId"
                      element={<Liguilla />}
                    />
                    <Route
                      exact
                      path="/backoffice/resultados/:_concursoId"
                      element={<Resultados />}
                    />
                    <Route
                      exact
                      path="/backoffice/resultados-penca/:_pencaId"
                      element={<ResultadosPenca />}
                    />
                    <Route
                      exact
                      path="/backoffice/pronosticar/:_pencaId"
                      element={<Pronosticar />}
                    />
                    <Route
                      exact
                      path="/backoffice/pronosticar/:_pencaId/:_usuarioId"
                      element={<Pronosticar />}
                    />
                    <Route exact path="/penca" element={<PencaRedirect />} />
                    <Route
                      exact
                      path="/penca/:_pencaId"
                      element={<PencaRedirect />}
                    />
                    <Route exact path="/app" element={<AppRedirect />} />
                    <Route
                      exact
                      path="/backoffice/notificaciones"
                      element={<Notificaciones />}
                    />
                    <Route
                      exact
                      path="/backoffice/anunciantes"
                      element={<Anunciantes />}
                    />
                    <Route
                      exact
                      path="/backoffice/banners"
                      element={<BannersTablados />}
                    />
                    <Route
                      exact
                      path="/liguilla"
                      element={<ResultadosLiguilla />}
                    />
                    <Route
                      exact
                      path="/anuncios"
                      element={<CostoPublicidad />}
                    />

                    <Route exact path="/" element={<LandingWebsite />} />
                    <Route exact path="/penca" element={<PencaRedirect />} />
                    <Route
                      exact
                      path="/penca/:_pencaId"
                      element={<PencaRedirect />}
                    />
                    <Route path="/apk" element={<ApkDownload />} />
                    <Route path="/apk/*" element={<ApkDownload />} />
                  </Routes>
                </Container>
              </div>
            </BrowserRouter>
          </LocalizationProvider>
        </div>
      ) : !isLoading && !autorizado && reintentar >= 5 ? (
        <h3 className="center-screen">No se obtuvo respuesta del servidor</h3>
      ) : window.location.href.includes("/backoffice") ? (
        <Loading />
      ) : (
        <>
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<LandingWebsite />} />
              <Route exact path="/penca" element={<PencaRedirect />} />
              <Route
                exact
                path="/penca/:_pencaId"
                element={<PencaRedirect />}
              />
              <Route exact path="/app" element={<AppRedirect />} />
              <Route exact path="/anuncios" element={<CostoPublicidad />} />
              <Route exact path="/liguilla" element={<ResultadosLiguilla />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route path="/apk" element={<ApkDownload />} />
              <Route path="/apk/*" element={<ApkDownload />} />
              <Route
                path="/politicaprivacidad"
                element={<PoliticaPrivacidad />}
              />
              <Route path="/eliminarcuenta" element={<EliminarCuenta />} />
            </Routes>
          </BrowserRouter>
        </>
      )}
    </>
  );
};

export default PageRoutes;
