import React, { useEffect, useRef, useState } from "react";
import { Box, MenuItem, Select, Slider } from "@mui/material";
import "./Calculadora.scss";
import ReactPlayer from "react-player";
import { useRequestHandler } from "../utils/useRequestHandler";
import Servicios from "../services";
import { Button, Spinner } from "reactstrap";
import { IoReload } from "react-icons/io5";
import { formatearFechaDDMMYYHHMM } from "../utils/dates";

const CostoPublicidad = () => {
  const [nivelContratacion, setNivelContratacion] = useState("standard");
  const [usuarios, setUsuarios] = useState(9000);

  const calcularCosto = () => {
    return (usuarios / 3).toFixed(0);
  };

  function valuetext(value) {
    return `${value}`;
  }

  const handleUsuariosChange = event => {
    setUsuarios(event.target.value);
  };

  const handleMouseWheel = e => {
    const container = containerRef.current;
    if (!container) return;

    const currentScroll = container.scrollTop;
    const scrollAmount = 200; // Adjust as needed
    const deltaY = e.deltaY;

    if (deltaY > 0) {
      container.scrollTop = Math.min(
        container.scrollHeight - container.clientHeight,
        currentScroll + scrollAmount
      );
    } else {
      container.scrollTop = Math.max(0, currentScroll - scrollAmount);
    }
  };

  const containerRef = useRef(null);

  return (
    <div id="calculadora-page" onWheel={handleMouseWheel} ref={containerRef}>

      <div id="paso7" className="container">


        <div className="calculadora text-center justify-text-around">
          <br></br>
          <span className="probalo hablemos-titulo">
            ¡Anunciá en Carnaval App y pagá una única vez por TODO EL CARNAVAL 2025!
          </span>
        </div>

        <div className="calculadora mt-4">

          <div className=" d-flex row justify-content-center">
            <div className="banners">
              <div className="banner-example total">
                <div className="banner-image banner-standard"></div>
                <div className="banner-info">
                  <h2>Estándar</h2>
                  <p>Intercalado entre la programación de tablados</p>
                  <p>Dimensiones: 274px X 40px</p>
                  <div className="probalo">
                    <span>    UYU 4000 {" "}
                      <span style={{ fontSize: "smaller" }}> IVA Inc.</span>{" "}</span>
                  </div>
                </div>
              </div>
              <div className="banner-example total">
                <div className="banner-image banner-destacado"></div>
                <div className="banner-info">
                  <h2>Destacado</h2>
                  <p>Intercalado entre la programación de tablados</p>
                  <p>Dimensiones: 274px X 260px</p>
                  <div className="probalo">
                    <span>    UYU 6000 {" "}
                      <span style={{ fontSize: "smaller" }}> IVA Inc.</span>{" "}</span>
                  </div>
                </div>
              </div>
              <div className="banner-example total">
                <div className="banner-image banner-cabecera"></div>
                <div className="banner-info">
                  <h2>Cabecera</h2>
                  <p>Al desplegar o compartir la información de un tablado</p>
                  <p>Dimensiones: 375px X 108px</p>
                  <div className="probalo">
                    <span>    UYU 7000 {" "}
                      <span style={{ fontSize: "smaller" }}> IVA Inc.</span>{" "}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <br></br>
          <div className="hablemos-container">
            <span className="hablemos-texto my-1">
              * Precios unitarios por banner interactivo. Cada banner aparecerá aleatoriamente en la app durante todo el Carnaval 2025.
            </span>
          </div>
        </div>

        <div className="col text-center">
          <a
            target="_blank"
            href={`https://wa.me/59899032442?text=Hola+Carnaval+App!+Quiero+anunciar+en+la+app&type=phone_number&app_absent=0`}
          >
            <Button className="enviar-pedido" variant={"contained"}>
              <div class="whatsapp-content">
                <span>QUIERO ANUNCIAR</span>
                <div class="whatsapp-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" height="33" width="32" viewBox="0 0 448 512">
                    <path fill="#ffffff"
                      d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                  </svg>
                </div>
              </div>
            </Button>
          </a>
        </div>
        <a
          href="mailto:hola@carnavalapp.uy"
          className="col row hablemos-mail"
        >
          <div className="col-auto mail-icon"></div>
          <div className="col-auto">hola@carnavalapp.uy</div>
        </a>
        <a
          href="https://wa.me/59899032442?text=Hola+Carnaval+App&type=phone_number&app_absent=0"
          className="col row hablemos-mail"
        >
          <div className="col-auto phone-icon"></div>
          <div className="col-auto">099 032 442</div>
        </a>


        <div style={{ height: "60px" }}></div>
      </div>
    </div>
  );
};

export default CostoPublicidad;
