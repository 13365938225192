import React, { useEffect } from "react";
import "./LandingStyles.scss";
import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";
import { showInfoMessage } from "../redux/actions";
import { Button } from "reactstrap";

const LandingWebsite = () => {
  const dispatch = useDispatch();

  return (
    <div id="landing-website" className="container-center">
      <div className="logo-carnavalapp" alt="Carnaval App"></div>
      <div
        className="logo-carnavalapp-eslogan"
        alt="La app del Carnaval Uruguayo"
      ></div>
      <div className="img-navaja-mobile"></div>
      <div className="texto-mobile">
        <div className="texto-titulo">¿Fan del carnaval uruguayo?</div>
        <div className="texto-descripcion">
          Ahora el carnaval más largo del mundo tiene una app para vos. Accedé a
          la programación de todos los tablados, obtené información de
          conjuntos, armá tu penca, competí con amigos y ganá increíbles
          premios.
        </div>
      </div>
      <div className="navaja-y-texto d-flex row">
        <div className="img-navaja col-6"></div>
        <div className="texto col-6">
          <div className="texto-titulo">¿Fan del carnaval uruguayo?</div>
          <div className="texto-descripcion">
            Ahora el carnaval más largo del mundo tiene una app para vos. Accedé
            a la programación de todos los tablados, obtené información de
            conjuntos, armá tu penca, competí con amigos y ganá increíbles
            premios.
          </div>
        </div>
      </div>

      <div className=" video-container">
        <ReactPlayer
          className="video-player"
          light={
            <img
              className="preview-video"
              src="./videopreview.png"
              alt="Carnaval App"
            ></img>
          }
          url="./Carnaval-app.mp4"
          controls={true}
          muted={false}
          playing={true}
          playIcon={<img className="play-icon" src="./play_circle.png"></img>}
        />
      </div>
      <div className="w-100 call-to-action">DESCARGÁ CARNAVAL APP</div>
      <div className="tiendas d-flex row">
        <a
          target={!process.env.REACT_APP_GOOGLE_PLAY_URL ? "_self" : "_blank"}
          className="google-play col"
          href={process.env.REACT_APP_GOOGLE_PLAY_URL ?? "javascript:void(0);"}
        ></a>
        <a
          target={!process.env.REACT_APP_GOOGLE_PLAY_URL ? "_self" : "_blank"}
          className="app-store col"
          href={process.env.REACT_APP_APP_STORE_URL ?? "javascript:void(0);"}
        ></a>
      </div>
      <div className="hablemos">
        <div className="icono-mascara d-flex row"></div>
        <div className="hablemos-titulo d-flex row">¡Hablemos!</div>
        <div className="hablemos-texto d-flex row">
          Si estás interesado en explorar oportunidades publicitarias en
          Carnaval App o solicitar más información, estamos aquí para ayudarte.
        </div>
        <div className=" d-flex row">
          <div className="col text-center mt-4">
            <a href={`./anuncios`}>
              <Button className="enviar-pedido" variant={"contained"}>
                SABER MÁS
              </Button>
            </a>
          </div>
        </div>
        <a
          href="mailto:hola@carnavalapp.uy"
          className="d-flex row hablemos-mail"
        >
          <div className="col-auto mail-icon"></div>
          <div className="col-auto">hola@carnavalapp.uy</div>
        </a>
      </div>
    </div>
  );
};

export default LandingWebsite;
